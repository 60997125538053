<template>
    <section
        class="o-flexible-module"
        :class="{ 'o-flexible-module--flush': settings.spacing == 'flush' }">
        <div class="grid-container" v-if="settings.showComponentTitle">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">
                        Map
                    </div>
                </div>
            </div>
        </div>

        <div class="c-map">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div class="c-map__title" data-aos="fade-up">
                            {{ title }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 medium-5 large-5 cell">
                        <div class="c-map__content-wrapper">
                            <div class="c-map__content s-wp-cms" v-html="content" v-if="content"></div>

                            <NuxtLink :to="$convertUrl(link.url)" class="c-map__link" v-if="link && link.url">
                                {{ link.title }}
                            </NuxtLink>
                        </div>
                    </div>

                    <div class="small-4 medium-auto large-2 large-offset-1 cell">
                        <div class="c-map__timezone-wrapper">
                            <div class="c-map__timezone">New York</div>
                            <div class="c-map__time">{{ getCurrentTime(currentTime, 'America/New_York') }}</div>
                        </div>
                    </div>

                    <div class="small-4 medium-auto large-2 cell">
                        <div class="c-map__timezone-wrapper">
                            <div class="c-map__timezone">London</div>
                            <div class="c-map__time">{{ getCurrentTime(currentTime, 'Europe/London') }}</div>
                        </div>
                    </div>

                    <div class="small-4 medium-auto large-2 cell">
                        <div class="c-map__timezone-wrapper">
                            <div class="c-map__timezone">Singapore</div>
                            <div class="c-map__time">{{ getCurrentTime(currentTime, 'Asia/Singapore') }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div class="c-map__image" data-aos="fade-up">
                            <client-only>
                                <Vue3Lottie
                                    :animationLink="animationUrl"
                                    :height="'100%'"
                                    :width="'100%'"
                                />
                            </client-only>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: false
        },
        link: {
            type: Object,
            required: false
        },
        animationUrl: {
            type: String,
            required: false
        },
        settings: {
            showComponentTitle: {
                type: Boolean,
                required: true,
            },
            spacing: {
                type: String,
                required: true
            }
        }
    });

    const currentTime = useState('currentTime', () => useNow());

    const getCurrentTime = (currentTime, locale) => {
        const dayjs = useDayjs();

        const now = dayjs(currentTime).tz(locale).format('HH:mm:ss');

        return now;
    };

    onMounted(() => {
        setInterval(() => {
            currentTime.value = useNow()
        }, 1000);
    });
</script>
